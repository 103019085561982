<template>
   <vue-dropzone :id="id" ref="dropzone" class="obi-file-upload" :options="dropzoneOptions">
      <ObiText class="d-flex justify-content-center flex-column">
         <ObiIcon :icon="IconUpload" class="mb-3" />
         <ObiText v-html="errorMessage || $t('message')" />
      </ObiText>
   </vue-dropzone>
</template>

<script>
import uuid from 'uuidjs';
import { mapGetters } from 'vuex';
import VueDropzone from 'vue2-dropzone';
import { keys, omit, merge, get } from 'lodash';
//import { getSubdomain } from '@/libs/url-utils';

import IconUpload from '@/components/Icons/general/IconUpload.svg';

export default {
   name: 'ObiFileUpload',

   i18n: {
      messages: {
         tr: {
            message: 'Bu bölüme yüklemek istediğiniz dosyayı sürükleyebilirsiniz',
         },
         en: {
            message: 'You can drag and drop the file you want to upload to this section',
         },
      },
   },

   components: {
      VueDropzone,
   },

   props: {
      id: {
         type: String,
         default: get(uuid.genV4(), 'hexNoDelim'),
      },
      options: {
         type: Object,
      },
      url: {
         type: String,
         required: true,
      },
      collection: {
         type: String,
      },
      index: {
         type: Number,
         default: 1,
      },
      currentImageUrl: {
         type: String,
         default: null,
      },
   },

   computed: {
      ...mapGetters('auth', ['accessToken']),

      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      inputValue: {
         get() {
            return this.value;
         },
         set(val) {
            this.$emit('input', val);
         },
      },
      dropzoneOptions() {
         const defaults = {
            url: `${process.env.VUE_APP_API_URL}${this.url}?collection=${this.collection}`,
            thumbnailWidth: 200,
            thumbnailHeight: 100,
            maxFilesize: 2.0,
            previewTemplate: this.template(),
            acceptedFiles: 'image/png,image/jpeg,image/jpg',
            dictRemoveFileConfirmation: 'Bu imajı silmek istediğinize emin misiniz?',
            headers: { Authorization: `Bearer ${this.accessToken}`, 'x-tenant': 'panel' },
         };

         return merge(defaults, this.options);
      },
   },

   data() {
      return {
         IconUpload,
         errorMessage: null,
      };
   },

   mounted() {
      if (this.$props.currentImageUrl !== null) {
         console.log(this.$props.currentImageUrl);
         this.$refs.dropzone.manuallyAddFile(
            { size: 123, name: this.$props.index, type: 'image/*' },
            this.$props.currentImageUrl
         );
      }

      this.$refs.dropzone.$on('vdropzone-success', (...args) => {
         this.inputValue = args[1].data;
         this.$emit('change', args[1].data, this.index);
      });

      this.$refs.dropzone.$on('vdropzone-error', () => {
         this.$refs.dropzone.removeAllFiles();
         this.errorMessage = 'Yükleme sırasında hata oluştu. Lütfen tekrar deneyiniz!';
      });

      this.$refs.dropzone.$on('vdropzone-removed-file', (file) => {
         this.$emit('removed', file, this.index);
      });
   },
   methods: {
      template() {
         return `<div class="dropzone-previews-item dz-preview dz-file-preview">
                    <figure class="dz-image not-hover">
                            <img data-dz-thumbnail />
                    </figure>
                    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                    <div class="dz-buttons text-center">
                        <button class="obi-button btn btn-wider btn-danger" data-dz-remove>
                           <span class="mdi mdi-delete"> Remove</span>
                        </button>
                    </div>
                </div>`;
      },
      deletePhoto() {
         console.log('deletePhoto');
      },
   },
};
</script>

<style lang="scss">
.dropzone .dz-preview {
   display: block !important;
   margin: 0 !important;
}
</style>
